<template>
  <div>
    <el-row>
      <el-col :span="24" v-if="isShowForm">
        <Form :isModify="isModify" :data="modifyData" @successAdd="handleAdd" @onClose="handleClose" @onModify="handleModify" />
      </el-col>
      <el-col :span="24" v-else>
         <el-button class="add-btn" size="mini" type="primary" @click="chageIsShowForm(true)">添加</el-button>
        <Table
          class="table-wrapper"
          title="问答"
          :tableData="listData"
          :tableCols="getTableCols()"
          :onDelete="handleDelete"
          :onEdit="handleEdit"
          :currentPage="pageNo"
          :total="total"
          :onSizeChange="handlePageSize"
          :onCurrentPageChange="handlePageNo"
          :onMove="handleMove"
        ></Table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Table from '../table'
import Form from './form.vue'
import { tableCols, handleCols } from './config'
import {
  qaCreate,
  qaList,
  qaDelete,
  qaPut
} from '../../api/apis/qa'
import { putOrder } from '../../api/apis/drug'
export default {
  name: 'Qa',
  components: { Table, Form },
  data () {
    return {
      listData: [],
      pageNo: 1,
      pageSize: 10,
      keyword: '',
      isShowForm: false,
      total: 0,
      isModify: false,
      modifyData: null
    }
  },
  computed: {
    params () {
      return {
        pn: this.pageNo,
        ps: this.pageSize,
        keyword: this.keyword
      }
    }
  },
  watch: {
    params () {
      this.getList()
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async handleMove (index, data, direction) {
      const status = await putOrder({ type: 'qa', id: data.id, direction })
      status && this.getList()
    },
    handleClose () {
      this.isShowForm = false
      this.isModify = false
    },
    chageIsShowForm (bool) {
      this.isShowForm = bool
    },
    getTableCols () {
      return tableCols
    },
    getHandleCols () {
      return handleCols(this.handleDelete)
    },
    async getList () {
      const data = await qaList(this.params)
      if (!data) return
      const { content: { total, list } } = data
      this.total = total
      this.listData = list.map(x => ({ ...x, createUser: x?.create?.realName || '', updateUser: x?.update?.realName || '' }))
    },
    // handleEdit (data) {
    // },
    async handleDelete (row) {
      const { status, errorMsg } = await qaDelete(row.id)
      if (status) {
        this.$message.success('删除成功')
        this.getList()
      } else {
        this.$message.error(`删除失败,${errorMsg}`)
      }
    },
    async handleAdd (data) {
      if (!data) return
      const { status, errorMsg } = await qaCreate(data)
      if (status === 'ok') {
        this.$message.success('添加成功')
        this.isShowForm = false
        this.getList()
      } else {
        this.$message.error(`添加失败,${errorMsg}`)
      }
    },
    // 修改后的最新数据
    async handleModify (data) {
      const { id, answer, question } = data
      const { status } = await qaPut({ answer, id, question })
      if (status === 'ok') {
        this.$message.success('修改成功')
        this.handleClose()
        this.getList()
      } else {
        this.$message.error('修改失败')
      }
    },
    handleEdit (data) {
      this.modifyData = data
      this.isModify = true
      this.isShowForm = true
    },
    handlePageSize (pageSize) {
      this.pageSize = pageSize
    },
    handlePageNo (pageNo) {
      this.pageNo = pageNo
    }
  }
}
</script>

<style>
</style>
