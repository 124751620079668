<template>
  <div class="card">
  <p class="title"><i class="fa fa-th-large fa-lg"></i>问答</p>
  <i class='delete-icon el-icon-close' @click="handleClose"></i>
  <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
    <el-form-item label="问题" prop="question">
      <el-input clearable type='textarea' autosize v-model="ruleForm2.question" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="回答" prop="answer">
      <el-input clearable type='textarea' autosize v-model="ruleForm2.answer" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      <el-button @click="resetForm">重 置</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
export default {
  name: 'Form',
  props: {
    data: {
      default: {}
    },
    isModify: {
      default: false
    }
  },
  created () {
    if (!this.isModify) return
    const { answer, question } = this.data
    this.ruleForm2 = {
      answer,
      question
    }
  },
  data () {
    return {
      ruleForm2: {
        answer: '',
        question: ''
      },
      rules: {
        question: [
          { required: true, message: '请输入问题', trigger: 'blur' }
        ],
        answer: [
          { required: true, message: '请输入回复', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleClose () {
      this.resetForm()
      this.$emit('onClose')
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {}
          for (const key in this.ruleForm2) {
            obj[key] = this.ruleForm2[key]
          }
          this.isModify ? this.$emit('onModify', { ...this.data, ...obj }) : this.$emit('successAdd', obj)
        } else {
          this.$message.error('添加失败')
        }
      })
    },
    resetForm () {
      this.ruleForm2 = {
        answer: '',
        question: ''
      }
    }
  }
}
</script>
