import axios, { reLogin } from '../axios'

/**
 * @param {object} params
 * @param {string} answer
 * @param {string} question
 */
async function qaCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/qa/create', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {number} pn
 * @param {number} ps
 * @param {string} keyword
 */
async function qaList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/qa/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function qaDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/qa/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}

/**
 * @param {number} id
 */
async function qaPut (params) {
  try {
    const { data } = await axios.put('/qa/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
export {
  qaCreate,
  qaList,
  qaDelete,
  qaPut
}
